import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce0bfae8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mirroring" }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, [
      _createVNode(_component_font_awesome_icon, {
        icon: 'info-circle',
        class: "info"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("tuition-fees.mirroring")) + ": " + _toDisplayString(_ctx.nullLucatId) + " - " + _toDisplayString(_ctx.shortPersonalId) + " - " + _toDisplayString(_ctx.personName), 1)
    ]),
    _createElementVNode("button", {
      id: "stopMirroringBtn",
      class: "btn btn-outline-primary mr-2 stop-btn",
      "aria-label": _ctx.$t('tuition-fees.back'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onStopMirroringClicked()))
    }, _toDisplayString(_ctx.$t("tuition-fees.stop-mirroring")), 9, _hoisted_2)
  ]))
}