
import SessionStorageService from "@/services/sessionstorage-service";
import { SessionStorageKeys } from "@/constants/constants";
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The personal id of the user being mirrored by admin
     */
    personalId: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The name of the user being mirrored by admin
     */
    personName: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The lucat id of the user being mirrored by admin
     */
    lucatId: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    /**
     * The shortened personal id of only the last 10 digits
     */
    shortPersonalId(): string {
      const personalId = this.$props.personalId;
      return personalId ? personalId.substr(personalId.length - 10) : "";
    },
    /**
     * If the student cannot be found in Ladok, the lucat id will be null
     */
    nullLucatId(): string {
      const lucatId = this.$props.lucatId;
      return lucatId ? lucatId : "";
    },
  },
  methods: {
    /**
     * Routes to search user page
     */
    onStopMirroringClicked(): void {
      SessionStorageService.setItem(SessionStorageKeys.IsMirroringUser, false);
      this.$router.push({
        name: "MirrorUser",
      });
    },
  },
})
export default class MirroringUserBanner extends Vue {}
